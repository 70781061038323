body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.formStyles {
  display: flex;
  justify-content: center;
  margin: 25px;
  align-items: center;
}

.MuiInputBase-input {
  background: #f6f9fc !important;
}

.headingStyles {
  padding-left: 0px !important;
  margin-bottom: 5px;
  color: #0e0f10;
  font-weight: 500;
}

.labelStyles {
  padding-left: 0px !important;
  margin-bottom: 0px;
  color: #535b61;
  font-weight: 500;
}

.reqLabelStyles {
  padding-left: 0px !important;
  margin-bottom: 0px;
  color: #535b61;
  font-weight: 500;
}

.reqLabelStyles::after {
  content: "*";
  color: #e73e3e;
}
.MuiInputBase-input::placeholder {
  color: #abb7c0 !important;
  font-size: 15px;
}

.MuiInputBase-root.MuiFilledInput-root:before {
  border-bottom: 1px solid #0e0f10 !important;
}

.MuiInputBase-root.MuiFilledInput-root:hover {
  box-shadow: 0px 2px 10px rgba(14, 15, 16, 0.3);
}

.MuiInputBase-root.MuiFilledInput-root:hover:not(
    .Mui-disabled,
    .Mui-error
  ):before {
  border-bottom: 1px solid #5fb2ec !important;
}

.MuiInputBase-root.MuiFilledInput-root:after {
  border-bottom: 1px solid #5b87ef !important;
}

.MuiFormHelperText-root.Mui-error {
  color: #e73e3e !important;
  background-color: white;
  margin: 0px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 3px;
  display: flex;
}

.MuiInputBase-root.MuiFilledInput-root.Mui-error:before {
  border-bottom: 1px solid #e73e3e !important;
}
.MuiInputBase-root.MuiFilledInput-root.Mui-error:after {
  border-bottom: 1px solid #e73e3e !important;
}

input#filled-basic\ outlined-error-helper-text {
  background: #f9dada !important;
  color: #e73e3e;
}
.buttonStyles {
  color: #ffffff !important;
  border: none !important;
  width: 211px;
  background: #5b87ef !important;
  box-shadow: 0px 0px 10px rgba(14, 15, 16, 0.1) !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}

.secButtonStyles {
  color: #0e0f10 !important;
  width: 211px;
  background: #ffffff !important;
  box-shadow: 0px 0px 10px rgba(14, 15, 16, 0.1);
  border-radius: 5px !important;
  font-weight: 500;
  border: none !important;
}

.imgStyles {
  width: 37px !important;
  height: 40px;
}

.MuiFormControl-root {
  margin: 0px !important;
}

.MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #abb7c0 !important;
}

.MuiFormLabel-root-MuiInputLabel-root {
  color: #abb7c0 !important;
  font-size: 15px !important;
  font-weight: 200 !important;
  padding-top: 8px !important;
}

.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
